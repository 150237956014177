var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
        _vm.getUser.role.slug === 'business-developer-pro' ||
        _vm.getUser.role.slug == 'super-administrateur' ||
        _vm.getUser.role.slug == 'rh' ||
        _vm.getUser.role.slug == 'assistant-rh' ||
        _vm.getUser.role.slug == 'formateur'
      )?_c('div',[(_vm.isPageLoading)?_c('div',{staticClass:"d-flex justify-content-center mb-1",staticStyle:{"padding-top":"30vh"}},[_c('b-spinner',{staticClass:"mr-1",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"info"}})],1):_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#382DB8","title":null,"subtitle":null,"shape":"circle","next-button-text":"Suivant","finish-button-text":_vm.isRegisterEmployeeLoading ? 'Chargement...' : 'Enregistrer',"back-button-text":"Retour"},on:{"on-complete":_vm.applyAddEmployeeServiceAction}},[_c('tab-content',{attrs:{"title":"Service & Photos","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('small',{staticClass:"text-muted font-small-4"},[_vm._v("Renseignez les services fournie par cet(tte) employé(e)")])])],1),_vm._l((_vm.forms),function(form,index){return _c('b-row',{key:index},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Service offert","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Service offert","label-for":"services"}},[_c('v-select',{attrs:{"id":"role","reduce":function (service) { return service.id; },"label":"name","options":_vm.recurringServicesOption,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [(_vm.isServicesRecurringWithoutPaginationLoading)?_c('b-spinner',{staticClass:"align-middle text-info",staticStyle:{"width":"2.5rem","height":"2.5rem"}}):_c('span',{staticClass:"mt-1 font-medium-1"},[_vm._v("Ce service n'existe pas")])]},proxy:true}],null,true),model:{value:(form.recurring_service_id),callback:function ($$v) {_vm.$set(form, "recurring_service_id", $$v)},expression:"form.recurring_service_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"photos","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Photos","label-for":"photos","state":errors.length > 0 ? false : null}},[_c('b-form-file',{attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choissez les photos de l'employé","multiple":""},model:{value:(form.pictures),callback:function ($$v) {_vm.$set(form, "pictures", $$v)},expression:"form.pictures"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"expériences","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Expériences","label-for":"expériences","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"years_of_experience","reduce":function (range) { return range.value; },"label":"value","options":_vm.experiencesRange},model:{value:(form.years_of_experience),callback:function ($$v) {_vm.$set(form, "years_of_experience", $$v)},expression:"form.years_of_experience"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Prétention salariale","label-for":"salary_pretention"}},[_c('validation-provider',{attrs:{"name":"pretention salariale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"salary_pretention","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Entrez votre prétention salariale"},model:{value:(form.salary_pretention),callback:function ($$v) {_vm.$set(form, "salary_pretention", $$v)},expression:"form.salary_pretention"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"compétences","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Compétences","label-for":"compétences","state":errors.length > 0 ? false : null}},[_c('b-form-textarea',{attrs:{"id":"about","placeholder":"Textarea","rows":"5"},model:{value:(form.about),callback:function ($$v) {_vm.$set(form, "about", $$v)},expression:"form.about"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1)}),_c('b-row',{staticClass:"mt-4 mb-4"},[_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isRegisterEmployeeLoading},on:{"click":_vm.repeateAgain}},[_vm._v(" Ajouter un autre service ")])],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{attrs:{"variant":"danger","disabled":_vm.forms.length == 1},on:{"click":function($event){return _vm.removeItem(_vm.index)}}},[_vm._v(" Supprimer ")])],1)],1)],2)],1)],1)],1):_c('div',[_c('not-authorized')],1)}
var staticRenderFns = []

export { render, staticRenderFns }