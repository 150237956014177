<template>
<div v-if="
        getUser.role.slug === 'business-developer-pro' ||
        getUser.role.slug == 'super-administrateur' ||
        getUser.role.slug == 'rh' ||
        getUser.role.slug == 'assistant-rh' ||
        getUser.role.slug == 'formateur'
      ">
    <div v-if="isPageLoading" class="d-flex justify-content-center mb-1" style="padding-top: 30vh">
        <b-spinner variant="info" style="width: 3rem; height: 3rem" class="mr-1" />
    </div>
    <form-wizard v-else color="#382DB8" :title="null" :subtitle="null" shape="circle" next-button-text="Suivant" :finish-button-text="isRegisterEmployeeLoading ? 'Chargement...' : 'Enregistrer'" back-button-text="Retour" class="mb-3" @on-complete="applyAddEmployeeServiceAction">

        <!-- personal details tab -->
        <tab-content title="Service & Photos" :before-change="validationFormInfo">
            <validation-observer ref="infoRules" tag="form">
                <b-row>
                    <b-col cols="12" class="mb-2">
                        <small class="text-muted font-small-4">Renseignez les services fournie par cet(tte) employé(e)</small>
                    </b-col>
                </b-row>
                <b-row v-for="(form, index) in forms" :key="index">
                    <!-- Requested service -->
                    <b-col md="6">
                        <validation-provider #default="{ errors }" name="Service offert" rules="required">
                            <b-form-group label="Service offert" label-for="services">
                                <v-select id="role" v-model="form.recurring_service_id" :reduce="(service) => service.id" label="name" :options="recurringServicesOption" :state="errors.length > 0 ? false : null">
                                    <template v-slot:no-options>
                                        <b-spinner v-if="isServicesRecurringWithoutPaginationLoading" style="width: 2.5rem; height: 2.5rem" class="align-middle text-info" />

                                        <span v-else class="mt-1 font-medium-1">Ce service n'existe pas</span>
                                    </template>
                                </v-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider #default="{ errors }" name="photos" rules="">
                            <b-form-group label="Photos" label-for="photos" :state="errors.length > 0 ? false : null">
                                <b-form-file v-model="form.pictures" accept=".jpg, .png, .gif, .jpeg" placeholder="Choissez les photos de l'employé" multiple />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider #default="{ errors }" name="expériences" rules="required">
                            <b-form-group label="Expériences" label-for="expériences" :state="errors.length > 0 ? false : null">
                                <v-select id="years_of_experience" v-model="form.years_of_experience" :reduce="(range) => range.value" label="value" :options="experiencesRange" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Prétention salariale" label-for="salary_pretention">
                            <validation-provider #default="{ errors }" name="pretention salariale" rules="required">
                                <b-form-input id="salary_pretention" v-model="form.salary_pretention" type="number" :state="errors.length > 0 ? false : null" placeholder="Entrez votre prétention salariale" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!-- <b-col md="6">
                   <b-form-group
                  label="Montant à épargner"
                  label-for="montant_epargne"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="montant à épargner"
                    rules="required"
                  >
                    <b-form-input
                       id="montant_epargne"
                      v-model="form.salary_pretention"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Entrez le montant à épargner"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                   </b-form-group>
                </b-col> -->
                    <b-col md="12">
                        <validation-provider #default="{ errors }" name="compétences" rules="required">
                            <b-form-group label="Compétences" label-for="compétences" :state="errors.length > 0 ? false : null">
                                <b-form-textarea id="about" v-model="form.about" placeholder="Textarea" rows="5" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row class="mt-4 mb-4">
                    <b-col md="3">
                        <b-button variant="primary" :disabled="isRegisterEmployeeLoading" @click="repeateAgain">
                            Ajouter un autre service
                        </b-button>
                    </b-col>
                    <b-col md="3">
                        <b-button variant="danger" :disabled="forms.length == 1" @click="removeItem(index)">
                            Supprimer
                        </b-button>
                    </b-col>
                </b-row>
            </validation-observer>
        </tab-content>
    </form-wizard>
</div>
<div v-else>
    <not-authorized />
</div>
</template>

  
  
<script>
import {
    FormWizard,
    TabContent
} from "vue-form-wizard";
import vSelect from "vue-select";
import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
    BRow,
    BCol,
    BFormGroup,
    BSpinner,
    BButton,
    BFormTextarea,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormInvalidFeedback,
    BFormFile,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.bj";
import {
    required,
    integer,
    beninPhoneNumberFormatRule
} from "@validations";
import {
    mapActions,
    mapGetters
} from "vuex";

export default {
    components: {
        ValidationProvider,
        BSpinner,
        vSelect,
        BFormFile,
        ValidationObserver,
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BFormTextarea,
        BButton,
        BFormInvalidFeedback,
        ToastificationContent,
        Cleave,
    },

    data() {
        return {

            forms: [{
                recurring_service_id: "",
                about: "",
                pictures: [],
                years_of_experience: "",
                salary_pretention: "",
            }, ],
            payload: {
                recurring_service_id: [],
                about: [],
                years_of_experience: [],
                salary_pretention: [],
                pictures: [],
            },
            phone: "",

            options: {
                phone: {
                    phone: true,
                    phoneRegionCode: "BJ",
                },
            },
            experiencesRange: [{
                    value: "0 à 1an",
                },
                {
                    value: "1 à 3 ans",
                },
                {
                    value: "3 à 5 ans",
                },
                {
                    value: "5 à 10 ans",
                },
                {
                    value: "Plus de 10 ans",
                },
            ],
            salaryRange: [{
                    value: "Entre 30000 & 50000",
                },
                {
                    value: "Entre 50000 & 120000",
                },
                {
                    value: "Entre 120000 & 200000",
                },
                {
                    value: "200000 & plus",
                },
            ],
            isPageLoading: true,
            pointFocalOption: [],
            recurringServicesOption: [],
            isRegisterEmployeeLoading: false,
            name: "",
            address: "",
            required,
            integer,
            mtnMobileMoneyNumber: "",
            floozMobileMoneyNumber: "",
            beninPhoneNumberFormatRule,
        };
    },

    computed: {
        ...mapGetters("services", [
            "getRecurringServices",
            "getRecurringServicesWithoutPagination",
        ]),
        ...mapGetters("offerType", ["getPointFocaux"]),
        ...mapGetters("auth", ["getUser"]),
        ...mapGetters(["isServicesRecurringWithoutPaginationLoading"]),
        phoneNumber() {
            return this.phone.replace(/\s/g, "");
        },
        mtn_number() {
            return this.mtnMobileMoneyNumber.replace(/\s/g, "");
        },
        flooz_number() {
            return this.floozMobileMoneyNumber.replace(/\s/g, "");
        },
    },
    created() {
        this.applyGetPointFocauxAndRecurringServices();
    },
    mounted() {
        if (this.getRecurringServicesWithoutPagination.length != 0) {
            this.getRecurringServicesWithoutPagination.forEach((element) => {
                this.recurringServicesOption.push({
                    id: element.id,
                    name: element.name,
                });
            });
        }

        this.configProAddressField();
    },
    watch: {
        isServicesRecurringWithoutPaginationLoading(val) {
            if (val === false) {
                this.getRecurringServicesWithoutPagination.forEach((element) => {
                    this.recurringServicesOption.push({
                        id: element.id,
                        name: element.name,
                    });
                });
            }
        },
    },
    methods: {
        ...mapActions("professionals", ["addEmployeeServiceAction", "getEmployeesAction", "getEmployeesDetailsAction"]),
        ...mapActions("services", [
            "getRecurringServicesAction",
            "getRecurringServicesWithoutPaginationAction",
        ]),

        ...mapActions("offerType", ["getPointFocalAction"]),
        applyGetPointFocauxAndRecurringServices() {
            if (this.getPointFocaux != null && this.getRecurringServices.length != 0) {
                this.getPointFocaux.data.forEach((element) => {
                    this.pointFocalOption.push({
                        id: element.id,
                        name: element.responsable_name,
                    });
                });
                this.getRecurringServicesWithoutPagination.forEach((element) => {
                    this.recurringServicesOption.push({
                        id: element.id,
                        name: element.name,
                    });
                });
                this.isPageLoading = false;
                this.point_focal_id = this.pointFocalOption.find((el) => el.name == "YLOMI").id;
            } else {
                this.getPointFocalAction().then((response) => {
                    response.data.data.forEach((element) => {
                        this.pointFocalOption.push({
                            id: element.id,
                            name: element.responsable_name,
                        });
                    });
                    this.point_focal_id = this.pointFocalOption.find((el) => el.name == "YLOMI").id;

                    this.getRecurringServicesWithoutPaginationAction().then(() => {
                        response.data.data.forEach((element) => {
                            this.recurringServicesOption.push({
                                id: element.id,
                                name: element.name,
                            });
                        });
                    });
                });
                this.isPageLoading = false;
            }
        },
        configProAddressField() {
            const input = document.getElementById("address");
            const options = {
                types: ["establishment"],
                componentRestrictions: {
                    country: ["BJ"]
                },
            };
            this.autocomplete = new google.maps.places.Autocomplete(input, options);

            this.autocomplete.addListener("place_changed", () => {
                const place = this.autocomplete.getPlace();
                this.payload.address = place.name;
            });
        },

        repeateAgain() {
            this.$refs.infoRules.validate().then((success) => {
                    if (success) {
                        this.forms.push({
                        recurring_service_id: "",
                        about: "",
                        pictures: [],
                        years_of_experience: "",
                        salary_pretention: "",
                    });
                    } else {
                        reject();
                    }
                });
            
        },

        removeItem(index) {
            this.forms.splice(index, 1);
        },

        applyAddEmployeeServiceAction() {

            this.isRegisterEmployeeLoading = true;
            this.forms.forEach((element) => {
                this.payload.recurring_service_id.push(element.recurring_service_id);
                this.payload.about.push(element.about);
                this.payload.salary_pretention.push(element.salary_pretention);
                this.payload.pictures.push(element.pictures);
                this.payload.years_of_experience.push(element.years_of_experience);

            });

            this.addEmployeeServiceAction({
                    employeeId: this.$route.params.id,
                    payload: this.payload
                })
                .then(() => {
                    this.getEmployeesDetailsAction(this.$route.params.id).then(() => {
                        this.isRegisterEmployeeLoading = false;
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Service ajouté avec succès",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.$router.push(`/employees/${this.$route.params.id}/details`);
                        this.payload = {
                            recurring_service_id: [],
                            about: [],
                            salary_pretention: [],
                            pictures: [],
                        };
                    });
                })
                .catch((error) => {
                    this.isRegisterEmployeeLoading = false;
                    this.payload.recurring_service_id = [];
                    this.payload.about = [];
                    this.payload.salary_pretention = [];
                    this.payload.pictures = [];
                    this.payload.years_of_experience = [];
                    console.log(error)
                    /* this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: "Error",
                          icon: "AlertTriangleIcon",
                          text: error.response.data.message,
                          variant: "danger",
                        },
                      },
                      {
                        position: "top-center",
                      }
                    ); */
                });
        },

        validationForm() {
            return new Promise((resolve, reject) => {
                this.$refs.accountRules.validate().then((success) => {
                    if (success) {
                        resolve(true);
                    } else {
                        reject();
                    }
                });
            });
        },

        validationFormInfo() {
            return new Promise((resolve, reject) => {
                this.$refs.infoRules.validate().then((success) => {
                    if (success) {
                        resolve(true);
                    } else {
                        reject();
                    }
                });
            });
        },

        validationFormAddress() {
            return new Promise((resolve, reject) => {
                this.$refs.addressRules.validate().then((success) => {
                    if (success) {
                        resolve(true);
                    } else {
                        reject();
                    }
                });
            });
        },

        validationFormSocial() {
            return new Promise((resolve, reject) => {
                this.$refs.socialRules.validate().then((success) => {
                    if (success) {
                        resolve(true);
                    } else {
                        reject();
                    }
                });
            });
        },
    },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
